import {Component, Input, Optional, TemplateRef, ViewChild} from '@angular/core';
import {FcComponent} from '../../../../@core/fc-component';
import {SlidePanelService} from '../../../slide-panel/slide-panel.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {FacilityChooserService, OrganizationFacility} from '../../../../@core/facility-chooser.service';
import {FilterService, PatientFilterOption} from '../../../../@core/filter.service';
import {ReviewTypeKey} from '../../../../definitions/definitions';
import {Filter} from '../../../../@core/filter';
import {SmartTableDefinition} from '../table/smart-table.component';
import {PhysicianDashboardService} from '../../../dashboard/physician-dashboard/physician-dashboard.service';
import {RequestHandler} from '../../../../@core/utils/request-handler';

@Component({
    selector: 'app-range-filtered-table',
    templateUrl: './range-filtered-table.component.html',
    styleUrls: ['./range-filtered-table.component.scss'],
})
export class RangeFilteredTable extends FcComponent {
    // Smart Table inputs
    @Input() requestHandler: RequestHandler<any, any>;
    @Input() initialData: any[] = [];
    @Input() definition: SmartTableDefinition;
    @Input() baseFilter: Filter;
    @Input() processData: (data: any) => any;
    @Input() limit: number;
    @Input() title: string;
    @Input() activePatientFilter: PatientFilterOption;
    @Input() reviewType: ReviewTypeKey;
    // Extended functionality
    @Input() permissions: string[] = ['PERMISSION_FILTER_ADVANCED'];
    @Input() detailedViewTableDef: SmartTableDefinition;
    @Input() checkForPermissions = true;
    @Input() customDetailTmp: TemplateRef<any>;

    @ViewChild('detailViewTemplate', {static: false}) detailViewTemplate;

    hasAdvancedFilterPermission = false;
    patientFilterOptions: PatientFilterOption[];
    detailData: any[] = [];
    filter: Filter;

    get dashboard() {
        return this.physicianDashboardService?.dashboard;
    }

    constructor(private slidePanelService: SlidePanelService,
                private permissionsService: NgxPermissionsService,
                private filterService: FilterService,
                fc: FacilityChooserService,
                @Optional() private physicianDashboardService: PhysicianDashboardService) {
        super(fc);
        this.filter = new Filter();
        this.filter.params = {
            ...this.baseFilter?.params,
            'days': null,
            ...this.fc.selected,
            ...(this.filterService.dateRange || {start_date: null, end_date: null, timezone: this.fc.timezone}),
        };
    }

    compOnInit() {
        this.filterService.patientFilterOptions$.subscribe(opts => {
            this.patientFilterOptions = opts;
            this.resetFilter();
        });
        if (this.checkForPermissions) {
            this.permissionsService.hasPermission(this.permissions).then(hasPermissions => {
                this.hasAdvancedFilterPermission = hasPermissions;
            });
        }
    }

    compOnFacChange(orgFac: OrganizationFacility) {
        this.filter.params = {...this.filter.params, ...orgFac};
    }

    getData() {
        this.cancelRequest$.next();
        this.requestHandler.call({
            physician: this.dashboard?.filter.params.physician || null,
            ...this.filter.params,
        }).subscribe(res => {
            this.detailData = this.processData ? this.processData(res) : res;
        });
    }

    getButtonText() {
        return RangeFilteredTable.getButtonText(this.initialData && this.limit ? this.initialData.length - this.limit : null, this.hasAdvancedFilterPermission);
    }

    static getButtonText(more: number, hasFilterPermission = true) {
        if (!hasFilterPermission) return `+${more} more`;
        return more > 0 ? `+${more} & More filters` : 'More filters';
    }

    onFilterChange(filterSubset: any) {
        this.filter.params = {...this.filter.params, ...filterSubset};
    }

    onPhysicianChange(physician: number) {
        this.filter.set('physician', physician);
    }

    onShowMore() {
        this.resetFilter();
        this.detailData = [...this.initialData];
        this.slidePanelService.add(this.detailViewTemplate, {customMarkup: true, size: 'lg'});
    }

    setPatientFilter(option: PatientFilterOption) {
        this.activePatientFilter = option;
        this.filter.params = {...this.filter.params, ...option.value};
    }

    resetFilter() {
        this.filter = new Filter();
        this.filter.params = {
            ...this.baseFilter?.params,
            'days': null,
            ...this.fc.selected,
            ...(this.filterService.dateRange || {start_date: null, end_date: null, timezone: this.fc.timezone}),
        };
        this.setPatientFilter(this.filterService.patientFilter || this.patientFilterOptions[0]);
    }
}
