<ng-container *ngIf="!isDetailDefault; else detailTemplate">
    <app-paginated-table [apiFunction]="apiFunction"
                         [requestHandler]="requestHandler"
                         [pageSize]="pageSize"
                         [page]="page"
                         [filters]="filters"
                         [validateFilters]="validateFilters"
                         [ordering]="ordering"
                         [orderingDirection]="orderingDirection"
                         [selectable]="selectable"
                         [definition]="definition"
                         [title]="title"
                         [emptyText]="emptyText"
                         [colTemplates]="colTemplates"
                         [insertTemplateCols]="insertTemplateCols"
                         [downloadEnabled]="downloadEnabled"
                         [showMoreButton]="showMoreButton"
                         [hasSorting]="hasSorting"
                         [hasScrollContainer]="hasScrollContainer"
                         [reviewType]="reviewType"
                         [showPagination]="false"
                         [processFn]="processFn"
                         (itemCount)="onCountChange($event)"
                         (orderingChange)="onOrderingChange('simple', $event)"
                         (orderingDirectionChange)="onOrderingDirectionChange('simple', $event)"
                         (selectionChange)="onSelectionChange($event)">
    </app-paginated-table>
    <button
        *ngIf="hasShowFiltersButton && (hasAdvancedFilterPermission || count)"
        (click)="openDetails()"
        class="btn btn-sm btn-block btn-light mt-3"
        data-test-id="more-filter-btn">
        {{getButtonText()}}
    </button>
</ng-container>

<ng-template #detailTemplate>
    <div class="panel-header" *ngIf="showFilters">
        <h4 class="panel-title">{{title || definition.title}}</h4>
        <div class="add-on">
            <div *ngIf="hasAdvancedFilterPermission"
                 class="filter-container mr-0">
                <div *ngIf="customSearchInput" class="filter-control">
                    <input class="form-control"
                           placeholder="{{customSearchInput}}"
                           [(ngModel)]="filtersToSet.params.search">
                </div>
                <ng-container *ngxPermissionsOnly="'PERMISSION_READ_PATIENTS_ALL'">
                    <app-physician-select *ngIf="dashboard?.physicianOptions"
                                          class="filter-control"
                                          [options]="dashboard.physicianOptions"
                                          (physicianChange)="onPhysicianChange($event)"
                                          [physician]="filtersToSet.params.physician">
                    </app-physician-select>
                </ng-container>
                <div class="filter-control" *ngIf="hasActivePatientFilter">
                    <fal i="user" class="icon text-primary mr-1"></fal>
                    <select class="body-select"
                            [ngModel]="activePatientFilter"
                            (ngModelChange)="setPatientFilter($event)">
                        <option *ngFor="let o of patientFilterOptions" [ngValue]="o">{{o.name}}</option>
                    </select>
                </div>
                <app-payer-filter [payerFilter]="filtersToSet.params.payer_category"
                                  (payerFilterChange)="updatePayerFilter($event)"
                                  class="filter-control">
                </app-payer-filter>
                <datepicker-range-select *ngIf="hasDateFilter"
                                         class="filter-control"
                                         (dateFilterChange)="onDateFilterChange($event)"
                                         [dateFilter]="dateFilter"
                                         [emitOnCloseOnly]="false"
                                         initInterval="NONE">
                </datepicker-range-select>
                <ng-container *ngIf="customToggleFilter">
                    <app-toggle class="filter-control"
                                [size]="customToggleFilter.size"
                                [label]="customToggleFilter.label"
                                [(ngModel)]="filtersToSet.params[customToggleFilter.paramKey]"
                                (ngModelChange)="onFilterToggle($event)">
                    </app-toggle>
                </ng-container>
            </div>
            <div class="d-lg-flex">
                <button class="btn btn-outline-primary btn-block"
                        (click)="getDetailData()"
                        data-test-id="slide-panel-filter-btn">
                    Filter
                </button>
                <button *ngIf="reviewType === 'LAB_TEST'"
                        class="btn btn-outline-primary btn-block mt-lg-0 ml-lg-3 no-line-break"
                        (click)="downloadLabs()"
                        [appUnderProcessing]="labDownloadPending"
                        [disabled]="!dateFilter.start_date">
                    <fal i="download"></fal>
                    Download
                </button>
            </div>
        </div>
    </div>
    <div class="panel-body">
        <app-paginated-table [apiFunction]="apiFunction"
                             [requestHandler]="requestHandler"
                             [pageSize]="detailPageSize"
                             [filters]="detailFilters"
                             [validateFilters]="validateFilters"
                             [ordering]="detailOrdering"
                             [orderingDirection]="detailOrderDirection"
                             [selectable]="selectable"
                             [definition]="definition"
                             [title]="title"
                             [emptyText]="emptyText"
                             [colTemplates]="colTemplates"
                             [insertTemplateCols]="insertTemplateCols"
                             [downloadEnabled]="detailDownloadEnabled"
                             [showMoreButton]="showMoreButton"
                             [hasSorting]="hasSorting"
                             [hasScrollContainer]="hasScrollContainer"
                             [reviewType]="reviewType"
                             [showDetail]="true"
                             [processFn]="processFn"
                             (orderingChange)="onOrderingChange('simple', $event)"
                             (orderingDirectionChange)="onOrderingDirectionChange('simple', $event)"
                             (selectionChange)="onSelectionChange($event)">
        </app-paginated-table>
    </div>
</ng-template>
