import {Component, Input, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {APIService} from '../../../../@core/api.service';
import {SlidePanelService} from '../../../slide-panel/slide-panel.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {RangeFilteredTable} from '../range-filtered-table/range-filtered-table.component';
import {FacilityChooserService} from '../../../../@core/facility-chooser.service';
import {FilterService, PatientFilter, PatientFilterOption} from '../../../../@core/filter.service';
import {DownloadService} from '../../../../@core/download.service';
import {filter, take, takeUntil} from 'rxjs/operators';
import {Filter} from '../../../../@core/filter';
import {ToastService} from '../../../../@core/toast.service';
import {PhysicianDashboardService} from '../../../dashboard/physician-dashboard/physician-dashboard.service';
import {DatepickerRangeFilterInterface} from '../datepicker/datepicker-range-select.component';
import {PaginatedTableComponent} from '../paginated-table/paginated-table.component';
import {PrintService} from '../../../../@core/print.service';
import {DatePipe} from '@angular/common';

interface DetailFilter extends PatientFilter, DatepickerRangeFilterInterface {
    payer_category?: string;
    physician?: number;
    days?: number;
    search?: string;
    [key: string]: any;
}

@Component({
    selector: 'app-paginated-table-details',
    templateUrl: './paginated-table-details.component.html',
})
export class PaginatedTableDetailsComponent<T extends {id?: number; selected?: boolean} = any> extends PaginatedTableComponent<T> implements OnInit, OnDestroy {
    @Input() isDetailDefault = false;

    @Input() detailPageSize = 100;
    @Input() showFilters = false;
    @Input() usePatientFilters = false;
    @Input() detailOrdering: string;
    @Input() detailOrderDirection: 'asc' | 'desc' = 'asc';
    @Input() activePatientFilter: PatientFilterOption;

    @Input() detailDownloadEnabled = true;
    @Input() hasShowFiltersButton = true;
    @Input() permissions: string[] = ['PERMISSION_FILTER_ADVANCED'];
    @Input() customSearchInput: string;
    @Input() hasActivePatientFilter = true;
    @Input() hasDateFilter = true;
    @Input() customToggleFilter: {label: string; paramKey: string; size: 'sm' | 'md' | 'lg'};

    @ViewChild('detailTemplate', {static: true}) detailTemplate;

    detailFilters = new Filter<DetailFilter>();
    filtersToSet = new Filter<DetailFilter>();
    dateFilter: {start_date?: Date; end_date?: Date; timezone?: string; days?: number};
    hasAdvancedFilterPermission = false;
    patientFilterOptions: PatientFilterOption[];

    get dashboard() {
        return this.physicianDashboardService?.dashboard;
    }

    constructor(private permissionsService: NgxPermissionsService,
                private filterService: FilterService,
                private ds: DownloadService,
                private toastService: ToastService,
                slidePanelService: SlidePanelService,
                printService: PrintService,
                fc: FacilityChooserService,
                datePipe: DatePipe,
                @Optional() private physicianDashboardService: PhysicianDashboardService) {
        super(printService, fc, datePipe, slidePanelService);
        this.permissionsService.hasPermission(this.permissions).then(hasPermissions => {
            this.hasAdvancedFilterPermission = hasPermissions;
        });
    }

    getButtonText() {
        const more = this.count - this.filters.params.page_size;
        return RangeFilteredTable.getButtonText(more, this.hasAdvancedFilterPermission);
    }

    ngOnInit() {
        this.filterService.patientFilterOptions$.subscribe(opts => this.patientFilterOptions = opts);
        if (this.isDetailDefault) {
            this.resetFilters();
            // TODO: investigate why this is needed
            this.filters.updates$.pipe(takeUntil(this.destroy$)).subscribe(newFilters => {
                this.detailFilters.params = {
                    ...this.filtersToSet.params,
                    ...newFilters,
                };
            });
        }
    }

    onCountChange(count) {
        this.count = count;
    }

    onOrderingChange(type: 'simple' | 'detail', ordering) {
        if (type === 'simple') {
            this.ordering = ordering;
        } else {
            this.detailOrdering = ordering;
        }
    }

    onOrderingDirectionChange(type: 'simple' | 'detail', direction) {
        if (type === 'simple') {
            this.orderingDirection = direction;
        } else {
            this.detailOrderDirection = direction;
        }
    }

    onDateFilterChange({start_date, end_date, days, timezone}: any) {
        if (start_date && end_date) {
            this.dateFilter = {start_date, end_date, days: null, timezone};
        } else {
            this.dateFilter = {start_date: null, end_date: null, days: days || this.filters.params.days, timezone};
        }
        this.filtersToSet.params = {...this.filtersToSet.params, ...this.dateFilter};
    }

    onFilterToggle(val: boolean) {
        this.filters.params[this.customToggleFilter.paramKey] = val as any;
        this.getDetailData();
    }

    setPatientFilter(option: PatientFilterOption) {
        if (!option) return;

        this.activePatientFilter = option;
        this.filtersToSet.params = {...this.filtersToSet.params, ...option.value};
    }

    updatePayerFilter(option) {
        this.filtersToSet.set('payer_category', option);
    }

    resetFilters() {
        this.filtersToSet = new Filter<DetailFilter>(this.filters.params);
        this.detailFilters = new Filter<DetailFilter>();
        this.onDateFilterChange(this.filtersToSet.params);
        if (this.usePatientFilters) this.setPatientFilter(this.filterService.patientFilter);
    }

    getDetailData() {
        this.detailFilters.params = {...this.filtersToSet.params};
    }

    openDetails() {
        this.resetFilters();
        this.getDetailData();
        // If the detail panel opens we reset the selection
        this.selectionChange.emit([]);
        this.slidePanelService.open(this.detailTemplate, {customMarkup: true, size: 'lg'});

        // If the detail panel closes we reset the selection
        this.slidePanelService.panelChange.pipe(
            filter(change => ['pop', 'empty'].includes(change.event)), take(1)
        ).subscribe(() => this.selectionChange.emit([]));
    }

    onSelectionChange(ids) {
        this.selectionChange.emit(ids);
    }

    onPhysicianChange(physician: number) {
        this.filtersToSet.set('physician', physician);
    }

    // TODO: move this logic away from here
    labDownloadPending = false;

    downloadLabs() {
        this.labDownloadPending = true;
        this.ds.downloadFileByUrl(APIService.urls.LabReportViewSet.download({
            ...this.filtersToSet.params,
        })).subscribe(() => {
            this.labDownloadPending = false;
        }, () => {
            this.toastService.error('An error occurred while trying to download labs');
            this.labDownloadPending = false;
        });
    }
}
