<ng-container *appRequestHandler="requestHandler">
    <app-smart-table [definition]="definition"
                     [data]="initialData"
                     [limit]="limit"
                     [showMoreButton]="false"
                     [downloadEnabled]="true"
                     [reviewType]="reviewType">
    </app-smart-table>
</ng-container>
<button *ngIf="hasAdvancedFilterPermission || initialData?.length > limit"
        (click)="onShowMore()"
        class="btn btn-sm btn-block btn-light mt-3"
        data-test-id="more-filter-btn">
    {{ getButtonText() }}
</button>

<ng-template #detailViewTemplate>
    <div class="panel-header">
        <h4 class="panel-title">{{title || definition.title}}</h4>
        <div class="add-on">
            <div *ngIf="hasAdvancedFilterPermission"
                 class="filter-container mr-0">
                <ng-container *ngxPermissionsOnly="'PERMISSION_READ_PATIENTS_ALL'">
                    <ng-container *ngIf="dashboard.physicianOptions">
                        <app-physician-select [options]="dashboard.physicianOptions"
                                              (physicianChange)="onPhysicianChange($event)"
                                              [physician]="filter.params.physician"
                                              class="filter-control">
                        </app-physician-select>
                    </ng-container>
                </ng-container>
                <div class="filter-control">
                    <fal i="user" class="icon text-primary mr-1"></fal>
                    <select class="body-select"
                            [ngModel]="activePatientFilter"
                            (ngModelChange)="setPatientFilter($event)">
                        <option *ngFor="let o of patientFilterOptions" [ngValue]="o">{{o.name}}</option>
                    </select>
                </div>
                <app-payer-filter [(payerFilter)]="filter.params.payer_category"
                                  class="filter-control">
                </app-payer-filter>
                <datepicker-range-select class="filter-control"
                                         (dateFilterChange)="onFilterChange($event)"
                                         [dateFilter]="filter.params"
                                         [emitOnCloseOnly]="false" initInterval="NONE">
                </datepicker-range-select>
            </div>
            <button *ngIf="hasAdvancedFilterPermission"
                    class="btn btn-outline-primary"
                    (click)="getData()"
                    data-test-id="slide-panel-filter-btn">
                Filter
            </button>
        </div>
    </div>
    <div class="panel-body">
        <div class="position-relative">
            <ng-container *ngIf="customDetailTmp">
                <ng-container *ngTemplateOutlet="customDetailTmp"></ng-container>
            </ng-container>
            <ng-container *appRequestHandler="requestHandler">
                <ng-container *ngIf="!customDetailTmp">
                    <app-smart-table [showDetail]="true"
                                     [data]="detailData"
                                     [definition]="definition"
                                     [downloadEnabled]="true"
                                     [hasLimit]="false"
                                     [reviewType]="reviewType">
                    </app-smart-table>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-template>
